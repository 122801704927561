<template>
  <SubscriptionCard>
    <Heading size="4">
      <Icon name="icon-map-pin" />
      {{ $t('subscription.gps_max.name') }}
    </Heading>

    <Paragraph :text="$t('subscription.gps_max.description')" />

    <SubscriptionCardFeatureList>
      <SubscriptionCardFeatureListItem :text="$t('subscription.gps_max.features.worksWith')" />
      <SubscriptionCardFeatureListItem :text="$tc('subscription.gps_max.features.maxGPS', maxGps)" />
    </SubscriptionCardFeatureList>

    <div class="amount-box">
      <label class="label text-is-normal">{{ $t('subscription.gps_max.amountOfDevices') }}</label>
      <div class="control">
        <div class="select">
          <select v-model="maxGps" :disabled="!session">
            <option
              v-for="amount in availableMaxGPSAmounts"
              :key="amount"
              :value="amount"
              :selected="maxGps === amount">
              {{ amount }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <SubscriptionCardPriceTag :session="session" :showPrice="canPurchase || canUpgradeToGpsX" />

    <SubscriptionCardHelpText v-if="willBeRefunded" :text="$t('subscription.willBeRefunded')" />

    <SubscriptionCardBuyGpsMaxButton :maxGps="maxGps" :session="session" />
  </SubscriptionCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import {
  SUBSCRIPTION_TYPE_GPS_MAX_PREFIX,
  SUBSCRIPTION_TYPE_GPS_MAX_DEVICES
} from "@/modules/subscription/store/subscriptionStore"

import SubscriptionCard from "./SubscriptionCard.vue"
import SubscriptionCardFeatureList from "./SubscriptionCardFeatureList.vue"
import SubscriptionCardFeatureListItem from "./SubscriptionCardFeatureListItem.vue"
import SubscriptionCardPriceTag from "./SubscriptionCardPriceTag.vue"
import SubscriptionCardHelpText from "./SubscriptionCardHelpText.vue"
import SubscriptionCardBuyGpsMaxButton from "./SubscriptionCardBuyGpsMaxButton.vue"

export default {
  components: {
    SubscriptionCard,
    SubscriptionCardFeatureList,
    SubscriptionCardFeatureListItem,
    SubscriptionCardPriceTag,
    SubscriptionCardHelpText,
    SubscriptionCardBuyGpsMaxButton
  },

  props: {
  },

  data () {
    return {
      maxGps: 1,
      availableMaxGPSAmounts: [],
      sessions: {}
    }
  },

  computed: {
    ...mapGetters({
      language: "ui/language",
      canPurchase: "subscription/canPurchase",
      weight: "subscription/weight",
      isStripe: "subscription/isStripe",
      isAppStore: "subscription/isAppStore",
      isGooglePlay: "subscription/isGooglePlay",
      canUpgradeTo: "subscription/canUpgradeTo",
      willBeRefunded: "subscription/willBeRefunded"
    }),

    session () {
      return this.sessions[this.maxGps]
    },

    canUpgradeToGpsX () {
      const canUpgrade = this.canUpgradeTo(30 + this.maxGps * 10)
      return canUpgrade
    }
  },

  watch: {
    language () {
      this.getAllSessions()
    }

    // maxGps () {
    //   this.getSession()
    // }
  },

  async created () {
    for (let gpsDevicesAmount = 1; gpsDevicesAmount <= SUBSCRIPTION_TYPE_GPS_MAX_DEVICES; gpsDevicesAmount++) {
      const localWeight = 30 + gpsDevicesAmount * 10
      if (this.weight < localWeight) {
        this.availableMaxGPSAmounts.push(gpsDevicesAmount)
      }
    }

    this.maxGps = this.availableMaxGPSAmounts[0]
    this.getAllSessions()
  },

  methods: {
    ...mapActions({
      purchase: "subscription/purchase",
      checkout: "subscription/checkout"
    }),

    async getAllSessions () {
      this.sessions = {}

      this.availableMaxGPSAmounts.forEach(amount => {
        this.loadSession(amount)
      })
    },

    async loadSession (amount) {
      const subscriptionType = `${SUBSCRIPTION_TYPE_GPS_MAX_PREFIX}${amount}`
      const session = await this.purchase({
        type: subscriptionType,
        returnPath: this.$route.path
      })

      const updatedSessions = { ...this.sessions }
      updatedSessions[amount] = session

      this.sessions = updatedSessions
    },

    onToggleBenefitsVisible () {
      this.benefitsVisible = !this.benefitsVisible
    }
  }
}
</script>

<style scoped>
.amount-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1em;
}

.amount-box .label {
  padding-top: 5px;
  margin-right: 1em;
}
</style>
