<template>
  <ul class="push-top-xl push-bottom-xl">
    <SubscriptionCardFeatureListItem
      v-for="(feature, i) in features()"
      :key="i"
      :text="feature">
    </SubscriptionCardFeatureListItem>

    <slot />
  </ul>
</template>

<script>
import SubscriptionCardFeatureListItem from './SubscriptionCardFeatureListItem.vue'

export default {
  components: {
    SubscriptionCardFeatureListItem
  },

  props: {
    subscription: {
      type: String,
      default: ''
    }
  },

  methods: {
    // Can not be computed since the value will be cached and won't react to language changes.
    features () {
      return this.subscription.length > 0 ? this.$i18n.t(`subscription.${this.subscription}.features`) : []
    }
  }
}
</script>

<style scoped>
ul {
  font-size: 87.5%;
  padding-left: 1rem;
  margin: 0 auto;
  list-style: disc outside;
  text-align: left;
}
</style>
