<template>
  <div class="price-tag">
    <Heading size="5">
      <span v-if="showPrice">
        <InlineLoader v-if="isLoading" />

        <span v-else-if="session.finalPrice">
          <span v-if="session.percentOff === 0">
            {{ $t('subscription.price.yearly', { price: session.finalPrice, currency: currency }) }}
          </span>

          <span v-else>
            {{ session.finalPrice }} {{ currency }}
          </span>
        </span>
      </span>

      <span v-else v-html="'&nbsp;'" />
    </Heading>

    <p class="yearly-price">
      <span v-if="showPrice && !isLoading && session.percentOff > 0">
        {{ $t('subscription.price.ongoing', { price: session.regularPrice, currency: currency }) }}
      </span>

      <span v-else v-html="'&nbsp;'" />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    session: {
      type: Object,
      default: () => {}
    },

    showPrice: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    currency () {
      return this.session.currency.toLocaleUpperCase()
    },

    isLoading () {
      return this.showPrice ? !this.session : false
    }
  }
}
</script>

<style lang="scss" scoped>
.price-tag {
  text-align: center;
  margin-top: auto;

  .title {
    margin-top: 0.5rem;
  }

  .title:not(:last-child) {
    margin-bottom: 0 !important;
  }

  .yearly-price {
    font-size: 12px;
  }
}
</style>
