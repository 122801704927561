<template>
  <SubscriptionCard>
    <Heading size="4">
      <Icon name="icon-star" /> {{ $t('subscription.premium.name') }}
    </Heading>

    <Paragraph :text="$t('subscription.premium.description')" />

    <SubscriptionCardFeatureList>
      <SubscriptionCardFeatureListItem :text="$t('subscription.premium.features.seeThirdPartyGPS')" />
      <SubscriptionCardFeatureListItem :text="$t('subscription.premium.features.addThirdPartyGPS')" />
      <SubscriptionCardFeatureListItem :text="$t('subscription.premium.features.terrainMap')" />
    </SubscriptionCardFeatureList>

    <SubscriptionCardPriceTag :session="session" :showPrice="canPurchase || canUpgradeToPremium" />

    <SubscriptionCardHelpText v-if="willBeRefunded" :text="$t('subscription.willBeRefunded')" />

    <SubscriptionCardBuyPremiumButton :session="session" />

    <Dialog
      v-if="benefitsVisible"
      :title="$t('subscription.premium.benefits.title')"
      :closable="true"
      @close="onToggleBenefitsVisible">
      <Paragraph :text="$t('subscription.premium.benefits.description')" />
    </Dialog>
  </SubscriptionCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { SUBSCRIPTION_TYPE_PREMIUM, SUBSCRIPTION_TYPE_PREMIUM_WEIGHT } from '@/modules/subscription/store/subscriptionStore'

import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import SubscriptionCard from './SubscriptionCard.vue'
import SubscriptionCardFeatureList from './SubscriptionCardFeatureList.vue'
import SubscriptionCardFeatureListItem from './SubscriptionCardFeatureListItem.vue'
import SubscriptionCardPriceTag from './SubscriptionCardPriceTag.vue'
import SubscriptionCardHelpText from './SubscriptionCardHelpText.vue'
import SubscriptionCardBuyPremiumButton from './SubscriptionCardBuyPremiumButton.vue'

export default {
  components: {
    Dialog,
    SubscriptionCard,
    SubscriptionCardFeatureList,
    SubscriptionCardFeatureListItem,
    SubscriptionCardPriceTag,
    SubscriptionCardHelpText,
    SubscriptionCardBuyPremiumButton
  },

  data () {
    return {
      session: null,
      benefitsVisible: false
    }
  },

  computed: {
    ...mapGetters({
      language: 'ui/language',
      canPurchase: 'subscription/canPurchase',
      weight: 'subscription/weight',
      isStripe: 'subscription/isStripe',
      isAppStore: 'subscription/isAppStore',
      isGooglePlay: 'subscription/isGooglePlay',
      canUpgradeTo: 'subscription/canUpgradeTo',
      willBeRefunded: 'subscription/willBeRefunded'
    }),

    canUpgradeToPremium () {
      return this.canUpgradeTo(SUBSCRIPTION_TYPE_PREMIUM_WEIGHT)
    }
  },

  watch: {
    language () {
      this.getSession()
    }
  },

  async created () {
    this.getSession()
  },

  methods: {
    ...mapActions({
      purchase: 'subscription/purchase',
      checkout: 'subscription/checkout'
    }),

    async getSession () {
      this.session = null

      if (this.canPurchase || this.canUpgradeToPremium) {
        this.session = await this.purchase({
          type: SUBSCRIPTION_TYPE_PREMIUM,
          returnPath: this.$route.path
        })
      }
    },

    onToggleBenefitsVisible () {
      this.benefitsVisible = !this.benefitsVisible
    }
  }
}
</script>
