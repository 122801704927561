<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div>
          <h6 class="title is-6 is-marginless">
            {{ gps.model }} {{ gps.imei }}
          </h6>

          <p
            v-if="gps.paymentFailed"
            class="is-marginless"
            v-html="$t('subscription.gps.paymentFailed')"></p>
          <p
            v-if="!gps.paymentFailed && gps.autoRenew"
            class="is-marginless"
            v-html="$t('subscription.gps.nextPayment', { date: expires })"></p>
          <p
            v-if="!gps.paymentFailed && !gps.autoRenew"
            class="is-marginless"
            v-html="$t('subscription.gps.cancelled', { date: expires })"></p>
        </div>
      </div>

      <div
        v-if="gps.canPurchase"
        class="wrap-text"
        v-html="$t('subscription.gps.upgradeGpsSubscription')"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gps: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    expires () {
      return this.$dayjs(this.gps.expires).format("YYYY-MM-DD")
    }
  },

  methods: {
    buttonTitle () {
      return this.gps.autoRenew
        ? this.$i18n.t("subscription.gps.extend")
        : this.$i18n.t("subscription.gps.renew")
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1180px) {
  .level {
    align-items: flex-start;
    flex-direction: column;

    .level-right {
      margin-top: 1rem;
    }
  }
}

.wrap-text {
  white-space: normal; /* Allows the text to wrap to next line */
  word-wrap: break-word; /* Breaks the word to next line if it overflows */
  font-size: 87.5%;
}
</style>
