<template>
  <li>
    <span v-if="text.length > 0" v-html="text" />
    <slot v-else />
  </li>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
