<template>
  <SubscriptionCard>
    <Heading :text="$t('subscription.mySubscription')" size="4" />

    <Fragment v-if="autoRenew">
      <Paragraph :text="autoRenewTitle" />
      <Paragraph :text="autoRenewSubtitle" />
      <Paragraph :text="autoRenewNextPaymentDate" />
    </Fragment>

    <Fragment v-else>
      <Paragraph :text="expiresTitle" />
      <Paragraph v-if="!isLoading && !subscriptionExists" :text="upgradeText" />
    </Fragment>

    <SubscriptionCardPriceTag
      :show-price="canPurchase && !subscriptionExists"
      :session="session">
    </SubscriptionCardPriceTag>

    <Fragment v-if="canPurchase">
      <Paragraph v-if="subscriptionExists" :text="$t('subscription.upcoming')" />
      <SubscriptionCardHelpText :text="$t('subscription.firstPayment', { date: expiresDate })" />

      <Button
        type="primary"
        fullwidth
        :disabled="isLoading"
        :loading="isLoadingStripe"
        :title="buyButtonTitle"
        @click="onCheckout">
      </Button>
    </Fragment>

    <Fragment v-else>
      <SubscriptionCardHelpText />
      <Button class="invisible-button" />
    </Fragment>
  </SubscriptionCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { SUBSCRIPTION_TYPE_GPS, SUBSCRIPTION_TYPE_GPS_MAX_PREFIX } from '@/modules/subscription/store/subscriptionStore'

import SubscriptionCard from './SubscriptionCard.vue'
import SubscriptionCardPriceTag from './SubscriptionCardPriceTag.vue'
import SubscriptionCardHelpText from './SubscriptionCardHelpText.vue'

const STATUS_LOADING = 'loading'
const STATUS_SUBSCRIPTION_EXISTS = 'subscription-exists'
const STATUS_SUCCESS = 'success'

export default {
  components: {
    SubscriptionCard,
    SubscriptionCardPriceTag,
    SubscriptionCardHelpText
  },

  data () {
    return {
      session: null,
      status: null,
      isLoadingStripe: false
    }
  },

  computed: {
    ...mapGetters({
      type: 'subscription/type',
      expires: 'subscription/expires',
      autoRenew: 'subscription/autoRenew',
      canPurchase: 'subscription/canPurchase',
      devices: 'subscription/devices',
      price: 'subscription/getPrice',
      language: 'ui/language'
    }),

    autoRenewTitle () {
      return this.$i18n.t('subscription.autoRenew', { subscription: this.subscriptionTypeName })
    },

    autoRenewSubtitle () {
      const subscriptionType = this.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX) ? 'gps_max' : this.type

      return this.$i18n.t(`subscription.${subscriptionType}.autoRenew`)
    },

    autoRenewNextPaymentDate () {
      let date = this.type === SUBSCRIPTION_TYPE_GPS ? this.nextGpsPaymentDate : this.expires
      return date != null ? this.$i18n.t('subscription.nextPayment', { date: this.formatDate(date) }) : ''
    },

    nextGpsPaymentDate () {
      const devices = this.devicesWithUpcomingPayments
      return devices.length > 0 ? devices[0].expires : null
    },

    devicesWithUpcomingPayments () {
      const devices = this.devices || []
      const devicesWithUpcomingPayments = devices.filter(device => {
        return device.autoRenew && device.expires > new Date()
      })

      devicesWithUpcomingPayments.sort((a, b) => a.expires.getTime() - b.expires.getTime())
      return devicesWithUpcomingPayments
    },

    expiresTitle () {
      return this.$i18n.t('subscription.expires', {
        subscription: this.subscriptionTypeName,
        date: this.expiresDate
      })
    },

    upgradeText () {
      const subscriptionType = this.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX) ? 'gps_max' : this.type

      return this.$i18n.t(`subscription.${subscriptionType}.upgrade`)
    },

    subscriptionTypeName () {
      if (this.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)) {
        const namePrefix = this.$i18n.t('subscription.gps_max.name')

        return namePrefix + ' ' + this.type.substring(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX.length)
      }

      return this.$i18n.t(`subscription.${this.type}.name`)
    },

    expiresDate () {
      return this.expires ? this.formatDate(this.expires) : ''
    },

    buyButtonTitle () {
      const subscriptionType = this.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX) ? 'gps_max' : this.type

      return this.subscriptionExists ? this.$i18n.t('subscription.show') : this.$i18n.t(`subscription.${subscriptionType}.extend`)
    },

    isLoading () {
      return this.status === STATUS_LOADING
    },

    subscriptionExists () {
      return this.status === STATUS_SUBSCRIPTION_EXISTS
    }
  },

  watch: {
    language () {
      this.getSession()
    },
    type () {
      this.getSession()
    }
  },

  async created () {
    this.getSession()
  },

  methods: {
    ...mapActions({
      purchase: 'subscription/purchase',
      checkout: 'subscription/checkout',
      getBillingPortalSession: 'subscription/getBillingPortalSession'
    }),

    async getSession () {
      if (this.canPurchase) {
        this.session = null
        this.status = STATUS_LOADING

        try {
          this.session = await this.purchase({
            type: this.type,
            returnPath: this.$route.path
          })

          this.status = STATUS_SUCCESS
        } catch (error) {
          // TODO: This is a bit simplified because all errors will be handled as subscription exists.
          // We don't have any proper error handling when a generic server error occurs, instead the price will just continue spinning.
          // In the future, we could potentially look at the error message in the response from the server to differentiate between errors.
          console.log(error)
          this.status = STATUS_SUBSCRIPTION_EXISTS
        }
      }
    },

    onCheckout () {
      if (this.session !== null) {
        this.checkout(this.session)
      } else {
        this.openStripe()
      }
    },

    async openStripe () {
      this.isLoadingStripe = true

      try {
        const session = await this.getBillingPortalSession(this.$route.path)
        location.href = session.url
      } catch (error) {
        console.error(error)
        this.isLoadingStripe = false
        this.$notification.danger(this.$i18n.t('profile.settings.payment.getPortalSessionFailedErrorMessage'))
      }
    },

    formatDate (date) {
      return this.$dayjs(date).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>
.invisible-button {
  visibility: hidden;
}
</style>
