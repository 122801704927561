<template>
  <p class="subscription-card-help-text has-text-dark">
    <span v-if="text.length > 0" v-html="text" />
    <span v-else v-html="'&nbsp;'" />
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.subscription-card-help-text {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}
</style>
