<template>
  <div>
    <Button
      v-if="canPurchase || !(isAppStore || isGooglePlay)"
      type="primary"
      :disabled="!(canPurchase || canUpgrade)"
      :title="$t('subscription.premium.buy')"
      fullwidth
      @click="onCheckout">
    </Button>
    <div
      v-if="!canPurchase && isAppStore"
      fullwidth>
      {{ $t('subscription.buyInAppStore') }}
    </div>
    <div
      v-if="!canPurchase && isGooglePlay"
      fullwidth>
      {{ $t('subscription.buyInGooglePlay') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { SUBSCRIPTION_TYPE_PREMIUM_WEIGHT } from '@/modules/subscription/store/subscriptionStore'

export default {
  props: {
    session: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      isAppStore: 'subscription/isAppStore',
      isGooglePlay: 'subscription/isGooglePlay',
      weight: 'subscription/weight',
      canUpgradeTo: 'subscription/canUpgradeTo'
    }),

    canPurchase () {
      const canPurchase = this.$store.getters['subscription/canPurchase']
      return canPurchase && this.session !== null
    },

    canUpgrade () {
      const canUpgrade = this.canUpgradeTo(SUBSCRIPTION_TYPE_PREMIUM_WEIGHT)
      return canUpgrade && this.session !== null
    }
  },

  methods: {
    ...mapActions({
      purchase: 'subscription/purchase',
      checkout: 'subscription/checkout'
    }),

    async onCheckout () {
      try {
        await this.checkout(this.session)
      } catch (error) {
        this.$notification.danger(this.$t('subscription.purchaseErrorMessage'))
      }
    }
  }
}
</script>
