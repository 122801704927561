<template>
  <SubscriptionCard>
    <Heading :text="$t('subscription.standard.name')" size="4" />
    <Paragraph :text="$t('subscription.standard.description')" />

    <SubscriptionCardFeatureList :subscription="subscriptionType" />
    <SubscriptionCardPriceTag :session="session" />

    <Button
      type="primary"
      :disabled="!canPurchase"
      :title="$t('subscription.standard.buy')"
      fullwidth
      @click="onCheckout">
    </Button>
  </SubscriptionCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import { SUBSCRIPTION_TYPE_STANDARD } from "@/modules/subscription/store/subscriptionStore"

import SubscriptionCard from "./SubscriptionCard.vue"
import SubscriptionCardFeatureList from "./SubscriptionCardFeatureList.vue"
import SubscriptionCardPriceTag from "./SubscriptionCardPriceTag.vue"

export default {
  components: {
    SubscriptionCard,
    SubscriptionCardFeatureList,
    SubscriptionCardPriceTag
  },

  data () {
    return {
      session: null,
      subscriptionType: SUBSCRIPTION_TYPE_STANDARD
    }
  },

  computed: {
    ...mapGetters({
      language: "ui/language"
    }),

    canPurchase () {
      const canPurchase = this.$store.getters["subscription/canPurchase"]
      return canPurchase && this.session !== null
    }
  },

  watch: {
    language () {
      this.getSession()
    }
  },

  async created () {
    this.getSession()
  },

  methods: {
    ...mapActions({
      purchase: "subscription/purchase",
      checkout: "subscription/checkout"
    }),

    async getSession () {
      this.session = null

      this.session = await this.purchase({
        type: SUBSCRIPTION_TYPE_STANDARD,
        returnPath: this.$route.path
      })
    },

    async onCheckout () {
      try {
        await this.checkout(this.session)
      } catch (error) {
        this.$notification.danger(this.$t("subscription.purchaseErrorMessage"))
      }
    }
  }
}
</script>
