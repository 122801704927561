<!-- /profile/subscription -->
<template>
  <Fragment v-if="hasLoadedSubscription">
    <div v-if="purchaseSuccessful" class="has-max-width-wide">
      <Heading size="4">
        {{ $t("subscription.purchase.success.title") }}
      </Heading>

      <Paragraph :text="$t('subscription.purchase.success.text')" />
      <p>{{ redirectCounter }}</p>
    </div>
    <div class="columns has-max-width-wide is-multiline">
      <SubscriptionCurrentCard v-if="!purchaseSuccessful && hasSubscription" />
      <SubscriptionStandardCard
        v-if="!purchaseSuccessful && hasFreeOrTrialSubscription" />
      <SubscriptionPremiumCard
        v-if="!purchaseSuccessful && maybeCanUpgradeToPremium" />
      <SubscriptionGpsMaxCard
        v-if="!purchaseSuccessful && maybeCanUpgradeToGpsMax" />
    </div>

    <SubscriptionGpsDevice v-for="(gps, i) in devices" :key="i" :gps="gps" />
  </Fragment>

  <InlineLoader v-else :dark="true" />
</template>

<script>
import { mapGetters } from "vuex"

import {
  SUBSCRIPTION_TYPE_FREE,
  SUBSCRIPTION_TYPE_TRIAL,
  SUBSCRIPTION_TYPE_STANDARD,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_GPS,
  SUBSCRIPTION_TYPE_PREMIUM_WEIGHT,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_TYPE_GPS_MAX_DEVICES,
  SUBSCRIPTION_TYPE_GPS_MAX_PREFIX
} from "@/modules/subscription/store/subscriptionStore"

import SubscriptionStandardCard from "./SubscriptionStandardCard.vue"
import SubscriptionPremiumCard from "./SubscriptionPremiumCard.vue"
import SubscriptionCurrentCard from "./SubscriptionCurrentCard.vue"
import SubscriptionGpsDevice from "./SubscriptionGpsDevice.vue"
import SubscriptionGpsMaxCard from "./SubscriptionGpsMaxCard.vue"

export default {
  components: {
    SubscriptionStandardCard,
    SubscriptionPremiumCard,
    SubscriptionGpsMaxCard,
    SubscriptionCurrentCard,
    SubscriptionGpsDevice
  },

  data () {
    return {
      redirectCounter: 15
    }
  },

  computed: {
    ...mapGetters({
      type: "subscription/type",
      status: "subscription/getStatus",
      hasSubscription: "subscription/hasSubscription",
      devices: "subscription/devices",
      weight: "subscription/weight"
    }),

    purchaseSuccessful () {
      let success = this.$route.query.success === "true"
      return success
    },

    maybeCanUpgradeToPremium () {
      // No platform check performed, since we want to show Google Play/App Store info in the card
      return this.weight < SUBSCRIPTION_TYPE_PREMIUM_WEIGHT
    },

    maybeCanUpgradeToGpsMax () {
      // No platform check performed, since we want to show Google Play/App Store info in the card
      return this.weight < 30 + SUBSCRIPTION_TYPE_GPS_MAX_DEVICES * 10
    },

    hasLoadedSubscription () {
      return this.status === SUBSCRIPTION_STATUS_SUCCESS
    },

    hasFreeOrTrialSubscription () {
      return (
        this.type === SUBSCRIPTION_TYPE_FREE ||
        this.type === SUBSCRIPTION_TYPE_TRIAL
      )
    },

    hasStandardSubscription () {
      return this.type === SUBSCRIPTION_TYPE_STANDARD
    },

    hasPremiumSubscription () {
      return this.type === SUBSCRIPTION_TYPE_PREMIUM
    },

    hasGpsSubscription () {
      return (
        this.type === SUBSCRIPTION_TYPE_GPS ||
        this.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)
      )
    }
  },

  watch: {
    "$route.query.success": {
      immediate: true,
      handler (value) {
        if (value === "true") {
          this.startCountdown()
        }
      }
    }
  },

  methods: {
    startCountdown () {
      let countdownInterval = setInterval(() => {
        if (this.redirectCounter > 0) {
          this.redirectCounter--
        } else {
          clearInterval(countdownInterval)
          window.location.href =
            "/profile/subscription?reload=" + new Date().getTime() // Force browser to reload subscription page
        }
      }, 1000) // Decrease counter every 1 second
    }
  }
}
</script>
